@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  background-color: $color--black;
  color: $color--white;
  display: block;
  height: $site-banner-mobile-height;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include breakpoint($bp--largest-up) {
    height: $site-banner-height;
    min-height: $site-banner-height;
    color: $color--gray--lighter;
  }
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  .header-offers-banner-item {
    display: none;
    width: 100%;
    .header-offers-banner-formatter:not(.offers-banner-initialized) &:first-of-type,
    &.item-active {
      display: block;
    }
    .fade & {
      animation-name: fade;
      animation-duration: 1.5s;
    }
  }
  &__carousel {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    &-btn {
      .icon {
        color: $color--white;
        cursor: pointer;
        fill: $color--white;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .icon--arrow--left {
        #{$ldirection}: 35px;
        text-align: $ldirection;
        z-index: 1;
        @include breakpoint($bp--largest-up) {
          #{$ldirection}: 25%;
        }
      }
      .icon--arrow--right {
        #{$rdirection}: 35px;
        text-align: $rdirection;
        z-index: 1;
        @include breakpoint($bp--largest-up) {
          #{$rdirection}: 25%;
        }
      }
    }
  }
  &__arrow-prev {
    @include breakpoint($bp--largest-up) {
      #{$ldirection}: 25%;
    }
  }
  &__arrow-next {
    @include breakpoint($bp--largest-up) {
      #{$rdirection}: 25%;
    }
  }
  a {
    color: $color--white;
    text-transform: none;
    @include breakpoint($bp--largest-up) {
      color: $color--gray--lighter;
      text-transform: uppercase;
    }
  }
  &__close {
    cursor: pointer;
    display: block;
    position: relative;
    #{$rdirection}: 10px;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    button,
    svg {
      #{$rdirection}: 0;
    }
    .icon {
      fill: $color--white;
      height: 16px;
      width: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
